.ant-select-dropdown-menu {
  .ant-select-dropdown-menu-item {
    justify-content: flex-start !important;
  }
}

aside {
  .ant-drawer {
    .ant-drawer-mask {
      position: absolute;
    }
  }
}

.all-users-icon {
  border-radius: 50%;
  background-color: #479dff;
  color: #fff !important;
}

.filter-icon {
  height: 14px;
  width: 14px;
  margin-right: 5px;
}

.extra-filter-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-icon-container {
    padding: 0 5px;
    border: thin solid #d9d9d9;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #ff6b00;
      border: thin solid #ff6b00;

      i {
        color: #fff;
      }
    }

    i {
      color: #ff6b00;
    }
  }
}

.ant-drawer-body {
  padding: 12px !important;

  .age-filter-container {
    padding-bottom: 10px;
    border-bottom: 2px solid #ececec;

    .age-slider-container {
      .ant-slider-track {
        background-color: #ff6b00 !important;
      }

      .ant-slider-handle {
        border: solid 2px #ff6b00 !important;

        &:focus {
          box-shadow: 0 0 0 5px rgba(228, 101, 28, 0.178) !important;
        }
      }
    }
  }

  .gender-filter-container {
    padding: 10px 0;
    border-bottom: 2px solid #ececec;
  }

  .level-filter-container {
    padding: 10px 0;
    border-bottom: 2px solid #ececec;
  }

  .ant-tag-checkable {
    border: 1px solid #ff6b00;
    color: #ff6b00;

    &:hover {
      color: #ff6b00 !important;
      cursor: pointer;
    }
  }

  .ant-tag-checkable-checked {
    color: #fff;
    background-color: #ff6b00 !important;

    &:hover {
      color: #fff !important;
      cursor: pointer;
    }
  }

  .enrollment-date-filter-container {
    padding: 10px 0;
    border-bottom: 2px solid #ececec;

    .enrollment-date-picker-container {
      .ant-calendar-picker {
        width: 100% !important;

        input {
          font-size: 10px !important;
        }
      }
    }
  }

  .buttons-container {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-weight: 600;
      width: 45%;
      border-radius: 5px;
    }

    .apply-filters-button {
      border: 1px solid #ff6b00;
      background-color: #ff6b00;
      color: #fff;

      &:hover {
        cursor: pointer;
        color: #ff6b00;
        background-color: #fff;
      }
    }

    .reset-filters-button {
      border: 1px solid #cfcccc;
      background-color: #fff;
      color: #5d5d5d;

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #5d5d5d;
      }
    }
  }
}

.ant-calendar-time {
  .ant-calendar-footer {
    display: block;
  }
}

.filter-container {
  width: 100%;

  .body-cascader {
    display: flex;
    justify-content: space-between;
    border: thin solid #d5d5d5;
    font-weight: 600;
    height: 100%;
    border-radius: 2px;

    .text-container {
      padding: 5px 10px;
      width: 100%;

      .category-container {
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
          font-size: 18px;
          color: #ff6b00;
        }
      }

      .selected-category-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selected-filter-icon {
          font-size: 18px;
          margin-right: 5px;

          img {
            height: 18px;
            width: 18px;
          }
        }

        .current-filter {
          font-weight: 700;
          font-size: 11px;
        }
      }
    }

    .arrow-container {
      min-height: 20px;
      padding: 0 5px;
      display: flex;
      border-radius: 0 2px 2px 0;
      align-items: center;
      background-color: #ff6b00;
      color: #fff;
    }
  }
}

.ant-cascader-menu {
  height: 200px !important;

  .ant-cascader-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .category-filter {
    padding-right: 5px;
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
      padding-right: 5px;
    }
  }
}

.search-AZ-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-container {
    width: 85%;

    .ant-input-search {
      max-height: 25px !important;

      input {
        font-size: 12px;
        height: 100% !important;
        border-radius: 10px !important;
      }

      .ant-input-suffix {
        right: 0;
        display: flex;
        align-items: center;
        background: #ff6b00;
        padding: 0 10px;
        height: 100%;
        border-radius: 0 10px 10px 0 !important;

        i {
          color: #fff;
        }
      }
    }
  }

  .AZ-container {
    border: thin solid #d5d5d5;
    border-radius: 4px;
  }

  .off {
    i {
      padding: 3px;
      color: #ff6b00;
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
      background-color: #ff6b00;
      border: thin solid #ff6b00;

      i {
        color: #fff;
      }
    }
  }

  .ascending,
  .descending {
    i {
      padding: 3px;
      color: #fff;
      background-color: #ff6b00;
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
