.bootcamp-bar {
  padding: 7px 10px;
  margin-bottom: 0px;
  margin-top: 8px;
  display: block !important;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.57);

  .ant-row {
    margin: 0px !important;

    .ant-col {
      height: 50px;

      &.select {
        max-width: calc(301px - 9px);
      }

      &.with-divider {
        border-right: 2px solid #ececec;
      }
    }
  }

  .bootcamp-select {
    img {
      display: inline-block;
      width: 15%;
      top: -10px;
      position: relative;
      margin-right: 2%;
      width: 36px;
      height: 36px;
    }

    .select {
      display: inline-block;
      width: 83% !important;

      .ant-select {
        width: 100% !important;
      }
    }
  }

  .bootcamp-info {
    padding-left: 10px;
    display: grid;
    flex-direction: column;
    grid-template-columns: 15% 15% 15% 15% 40%;

    div {
      p:not(.label) {
        color: #6d6d6d;
        font-size: 13px;
        margin-bottom: 3px;
      }

      .capacity-progress {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #383838 !important;

        .ant-progress-line {
          width: 80%;
          margin-right: 10px;
          .ant-progress-inner {
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 0;
            .ant-progress-bg {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .meet {
    //float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      cursor: pointer;
    }
  }

  .label {
    color: #959595;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 766.9px) {
    .ant-row {
      height: auto;

      .ant-col {
        height: auto;

        &.select {
          max-width: 100%;
        }

        &.with-divider {
          border-right: none;
        }
      }
    }

    .bootcamp-select {
      display: block;
      width: 100%;
    }

    .bootcamp-info {
      width: 100%;
      grid-template-columns: 25% 25% 25% 25%;
      padding-left: 0;

      div:last-child {
        grid-column: span 4;
      }
    }
  }

  @media screen and (max-width: 991.9px) {
    .bootcamp-info {
      div {
        p:not(.label) {
          font-size: 11px;
        }
      }
    }
  }
}
