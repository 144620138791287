.ketogains-layout {
  font-family: 'Montserrat', sans-serif;

  header.ant-layout-header {
    background: #ffffff;
    color: #353535;
    font-weight: 500;
    padding: 0 25px;

    .right {
      display: initial;
      float: right;

      .username {
        margin-right: 10px;
        cursor: default;
        user-select: none;
      }

      .ant-avatar {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }

    @media only screen and (max-width: 575.9px) {
      padding: 0 5px;

      span.username,
      .ant-divider {
        display: none;
      }
    }
  }
}

.profile-menu-overlay {
  background-color: #5d5d5d;
  padding: 25px 40px !important;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    text-align: right;

    li {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }

      a {
        color: #fff;
        font-weight: 500;
        position: relative;
      }
    }
  }
}
