@import 'Header';

body {
  font-family: 'Montserrat', sans-serif !important;
}

.ketogains-layout {
  font-family: 'Montserrat', sans-serif;

  aside.student-list-container {
    border-radius: 2px;
    margin-top: 7px;
    padding: 7px 10px 9px;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.57);
    width: calc(100% - 38px) !important;
    flex-basis: calc(100% - 38px) !important;
    max-width: 300px !important;
    min-width: auto !important;

    &.ant-layout-sider-collapsed {
      padding: 0px;
      min-width: 0px !important;
      flex-basis: 0px !important;
    }

    .ant-layout-sider-zero-width-trigger {
      background: #ff6b00;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.57);
    }
  }

  main.ant-layout-content {
    padding: 0 20px;

    &.main-content {
      min-height: calc(100vh - 160px);
      padding: 0 0 0 8px;
      position: relative;

      .empty-dashboard {
        height: calc(100% - 7px);
        margin-top: 7px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: column-reverse;
        padding-left: 15px;

        h2 {
          font-size: 37px;
          color: #ffffff;
          font-weight: 600;
          text-transform: uppercase;

          span {
            display: block;
            font-weight: 900;
          }
        }
      }

      .ant-drawer {
        margin-top: 7px;
        margin-left: -8px;
        height: calc(100% - 7px);

        label {
          display: block;
          font-weight: 500;
          font-size: 11px;
          color: #959595;
          margin-bottom: 3px;
        }

        .ant-input.meet-title {
          border: none;
          border-bottom: 2px solid #c4c4c4;
          font-weight: 600;
          font-size: 18px;
          color: #717271;
          border-radius: 0px;

          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }

        .ant-calendar-picker {
          width: 100%;
        }

        .footer {
          right: 0;
          bottom: 0;
          width: 100%;
          padding: 20px 16px;
          background-color: #ffffff;
          text-align: right;

          button {
            margin-left: 10px;
          }
        }
      }

      @media only screen and (max-width: 1199.9px) {
        padding: 0;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }

    @media only screen and (max-width: 575.9px) {
      padding: 0 5px;
    }
  }

  footer.ant-layout-footer {
    padding: 4px;
    text-align: center;
    font-size: 10px;
  }

  .ant-row {
    //This fix the gutter configuration
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .keto-card {
    border-radius: 2px;
    margin-top: 7px;
    padding: 7px 10px 9px;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.57);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    header {
      border-bottom: 2px solid #ececec;
      padding: 10px 0px;
      margin-bottom: 5px;

      h3 {
        color: #383838;
        font-size: 11px;
        font-weight: 600;
        display: inline;
      }

      h5 {
        color: #383838;
        font-size: 9px;
        font-weight: 600;
        display: inline;
      }

      .actions {
        float: right;
        text-align: right;
        font-size: 9px;
        color: #383838;
        font-weight: 500;

        .ant-btn {
          font-size: 9px;
          font-weight: 600;
          letter-spacing: 1px;
          border-radius: 17.5px;
          background-color: #ff6b00;
          color: #ffffff;
          height: auto;
          padding: 4px 15px;
          vertical-align: middle;
          border: none;

          &:hover,
          &:active,
          &:visited,
          &:focus {
            border: none;
          }
        }
      }

      @media only screen and (max-width: 575.9px) {
        .actions {
          float: none;
          width: 100% !important;
          margin-bottom: 4px;
        }
      }
    }

    main {
      overflow-y: auto;
      clear: both;

      hr {
        border: none;
        border-bottom: 1px solid #ececec;
      }
    }

    @media screen and (max-width: 575.9px) {
      min-height: auto !important;
      max-height: max-content !important;
    }
  }

  .ant-avatar-circle {
    &.profile-picture {
      background: #ff6b00;
    }
  }

  .ant-select {
    width: 100%;

    .ant-select-selection {
      border: 1px solid #d5d5d5;

      &:hover {
        border: 1px solid #d5d5d5 !important;
      }

      .ant-select-selection-selected-value {
        color: #383838;
        font-size: 13px;
      }

      &.ant-select-selection--multiple {
        padding: 4px;
        .ant-select-selection__rendered {
          ul {
            li {
              background: rgba(196, 196, 196, 0.3);
              border-radius: 5px;
              padding: 6px 26px 6px 9px;
              margin-top: 8px;
              margin-right: 8px;
              height: auto;
              line-height: 1;

              .ant-select-selection__choice__remove {
                color: #ff6b00;
                font-size: 20px;
                top: 2px;

                i > svg > path {
                  stroke: #ff6b00;
                  stroke-width: 100;
                }
              }

              &.ant-select-search {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }

    .ant-select-arrow {
      color: #ff6b00;
      font-weight: bolder;
      font-size: 16px;
      top: 42%;
    }

    &.small {
      .ant-select-selection {
        height: 22px;

        .ant-select-selection__rendered {
          line-height: 20px;
          margin-left: 4px;
          margin-right: 20px;
        }

        .ant-select-selection-selected-value {
          font-size: 9px;

          b {
            font-weight: 600;
          }
        }
      }

      .ant-select-arrow {
        font-size: 14px;
        right: 5px;
      }
    }
  }

  .ant-calendar-picker {
    .ant-calendar-picker-icon {
      color: #ff6b00;
    }
  }

  .ant-time-picker {
    .ant-time-picker-icon {
      .ant-time-picker-clock-icon {
        color: #ff6b00;
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      color: #383838;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        color: #383838;
      }
    }

    .ant-tabs-tab-active {
      color: #383838;
      font-weight: 600;
      opacity: 1;
    }

    .ant-tabs-ink-bar {
      background: #ff6b00;
    }
  }

  .ant-btn {
    background: #ffffff;
    border: 1px solid #cfcccc;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    color: #5d5d5d;

    &:active,
    &:hover,
    &:focus,
    &:visited {
      border: 1px solid #cfcccc;
      color: #5d5d5d;
    }

    &.ant-btn-primary {
      background: #ff6b00;
      border: none;
      color: #ffffff;
    }
  }
}

.ant-select-dropdown {
  .ant-select-dropdown-menu-vertical {
    .ant-select-dropdown-menu-item {
      font-size: 13px;

      b {
        font-weight: 600;
      }
    }
  }

  &.small {
    .ant-select-dropdown-menu-vertical {
      .ant-select-dropdown-menu-item {
        font-size: 9px;
        padding: 3px 8px;
      }
    }
  }
}

.ant-tooltip {
  font-size: 10px !important;

  .ant-tooltip-inner {
    min-height: 26px;
  }
}

.ant-popover {
  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 8px 10px;

      .ant-popover-message {
        .ant-popover-message-title {
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          padding-left: 0px;
          padding-bottom: 9px;
          border-bottom: 1px solid #ff6b00;

          p {
            margin-bottom: 3px;
          }
        }
      }

      .ant-popover-buttons {
        margin-bottom: 0px;
        display: flex;
        flex-direction: column-reverse;

        .ant-btn {
          width: 100%;
          margin-left: 0px;
          margin-bottom: 12px;

          &:active,
          &:hover,
          &:focus,
          &:visited {
            border-color: #ff6b00;
            color: #ff6b00;
          }

          &.ant-btn-primary {
            background: #ff6b00;
            border-color: #ff6b00;

            &:active,
            &:hover,
            &:focus,
            &:visited {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
