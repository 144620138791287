.card {
  box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.57);
}

.ant-radio-group {
  display: inline-block !important;
}

.ant-alert-with-description {
  padding: 4px 4px 4px 64px !important;

  .anticon {
    color: #fff !important;
  }

  .ant-alert-message {
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
  }

  .ant-alert-description {
    font-size: 12px;
    color: #fff;
  }

  &.ant-alert-warning {
    background-color: rgba(255, 107, 0, 0.8);
    border: 1px solid #ff6b00;
  }
}

.modal-picture-zoomable {
  max-height: 95vh;
  padding-bottom: 0 !important;

  .ant-modal-content {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border-radius: 0;
    margin: 0 120px;

    .ant-modal-close {
      span {
        height: 30px;
        width: 30px;
        line-height: 30px;
      }
    }

    .ant-modal-header {
      padding: 5px 24px;
      border-bottom: none;
      border-radius: 6px;
      max-height: 30px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);

      .ant-modal-title {
        display: flex;
        align-items: center;

        .month-date {
          color: #000;
          font-size: 15px;
          font-weight: 600;
          margin-right: 10px;
        }

        .week-date {
          font-size: 12px;
          padding-left: 10px;
          border-left: 1px solid #ff6b00 !important;
          color: #ff6b00;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .ant-modal-body {
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-container {
        display: flex;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        min-height: 130px;
        max-height: calc(100vh - 180px);

        &:before {
          position: absolute;
          top: 50%;
          display: block;
          content: 'loading...';
          width: 100px;
          height: 20px;
          color: #fff;
        }

        img {
          max-width: 100%;
          object-fit: scale-down;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background-color: #fff;

      button {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
        width: 25%;
        margin: 0;
        border: none;
        font-size: 20px;
        color: #ff6b00;

        &:hover {
          cursor: pointer;
        }
      }

      .ant-btn[disabled] {
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: not-allowed;
        }
      }

      .button-left-bordered {
        border-left: 1px solid #ececec;
      }
    }
  }

  @media only screen and (max-width: 575.9px) {
    .ant-modal-content {
      margin: 0 10px;

      .ant-modal-body {
        min-width: auto !important;
        margin: 0 !important;
        min-height: calc(95vh - 100px);

        .img-container {
          width: 100% !important;
        }
      }
    }
  }
}

.update-student-data {
  width: 100%;
  min-height: 40px;
  text-align: end;

  button {
    background-color: #ff0000 !important;
    color: #fff !important;
    border-radius: 17.5px;
    border: solid 1px #ff0000;
    font-size: 15px;
    font-weight: 600;
    margin-right: 50px;

    &:hover {
      border: solid 1px #ff0000;
      background-color: #fff;
      color: #ff0000;
    }

    &:active {
      border: solid 1px #ff0000;
      background-color: #fff;
      color: #ff0000;
    }

    i {
      font-size: 18px;
      color: #fff;
    }
  }
}

.Popconfirm-center-button {
  .ant-popover-inner-content {
    padding: 10px !important;

    i {
      color: #ff0000 !important;
      font-size: 22px !important;
    }

    .ant-popover-message-title {
      padding-left: 30px !important;
      width: 340px;
      font-size: 13px;
      font-weight: 500;

      p {
        margin-bottom: 5px;
      }

      .uppertext {
        text-align: left;
        color: #5d5d5d;
        font-size: 16px;
      }

      .bottomtext {
        text-align: left;
        color: #959595;
        font-size: 12px;
      }
    }

    .ant-popover-buttons {
      flex-direction: row !important;
      margin-left: 20%;
      width: 40%;

      button {
        margin: 0 10px;
        min-height: 30px;
        padding: 2px 20px;
        font-size: 15px;
      }

      .ant-btn-sm {
        border: solid 1px #cfcccc;
      }
    }
  }
}

.modal-note-card {
  .ant-modal-header {
    display: flex;
    justify-content: center;

    .ant-modal-title {
      font-weight: 600;
      border-bottom: solid 1px #ff6b00;
      padding: 0 10px;
    }
  }

  .ant-modal-body {
    padding: 10px 24px;
  }

  .ant-modal-footer {
    padding: 0 24px;
    height: 8vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-btn {
      border-radius: 20px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
    }

    .modal-cancel-button {
      color: #ff6b00;
      border-color: #ff6b00;
    }

    .modal-save-note-button {
      background-color: #ff6b00;
      border-color: #ff6b00;
    }
  }
}

.modal-changes-note-card {
  .ant-modal-header {
    display: flex;
    justify-content: center;

    .ant-modal-title {
      font-weight: 600;
      border-bottom: solid 1px #ff6b00;
      padding: 0 10px;
    }
  }

  .ant-modal-body {
    padding: 10px 24px;

    .note-changes-container {
      p {
        margin: 0;
      }

      .changes-container {
        .section-title {
          font-size: 12px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 5px;
        }

        .section-item-container {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -5px;
          border-bottom: solid 1px rgba(176, 176, 176, 0.57);

          .left-bordered {
            border-left: solid 1px rgba(176, 176, 176, 0.57);
          }

          .section-changes-details-container {
            padding: 0 5px;
            margin: 5px 0;

            .item-name {
              font-size: 11px;
              color: rgb(0, 0, 0);
              text-transform: capitalize;
            }

            .value-change {
              font-size: 13px;
              font-weight: 500;

              .new-value {
                color: #000;
                font-weight: 600;
              }
            }
          }
        }
      }

      .notes-textarea-message {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .ant-modal-footer {
    padding: 0 24px;
    height: 8vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-btn {
      border-radius: 20px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
    }

    .modal-cancel-button {
      color: #ff6b00;
      border-color: #ff6b00;
    }

    .modal-save-note-button {
      background-color: #ff6b00;
      border-color: #ff6b00;
    }

    .modal-save-changes-note-button {
      background-color: #ff4613;
      border-color: #ff4613;
    }
  }
}

.starting-card {
  margin: 2px 5px !important;

  .ant-card-head {
    min-height: 30px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 7px 5px 7px 3px !important;
    display: flex;
    justify-content: space-between;

    .ant-row {
      width: 100%;
    }

    .ant-card-meta {
      display: flex;
      align-items: center;
    }
  }

  .ant-btn {
    height: 5vh !important;

    &:hover,
    &:active,
    &:focus {
      color: #ff6b00 !important;
      border-color: #ff6b00 !important;
    }
  }

  .dropdown-card {
    .ant-card-meta-avatar {
      padding-right: 6px;
    }

    .dropdown-button {
      padding: 0 10px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 70%;
      background-color: #efefef;
    }

    .dropdown-text-container {
      width: 90%;
      text-align: start;
      overflow: hidden;

      .dropdown-upperText {
        font-size: 9px !important;
      }

      .dropdown-bottomText {
        font-size: 12px !important;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .upsideItems {
    .ant-card-meta-avatar {
      padding-right: 6px;
    }

    button.dropdown-button {
      padding: 0 10px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 70%;
      background-color: #efefef;
    }
    span.dropdown-button {
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 70%;

      button {
        width: 100%;
      }
    }

    .dropdown-text-container {
      width: 90%;
      text-align: start;
      overflow: hidden;

      .dropdown-upperText {
        font-size: 12px !important;
        font-weight: 600;
      }

      .dropdown-bottomText {
        font-size: 9px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.starting-pictures-card {
  .ant-card-head {
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    .img-container {
      max-height: 15vh;

      img {
        max-height: 15vh;
      }
    }
  }
}

.info-card {
  .ant-card-head {
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 10px 24px !important;

    .info-card-container {
      .info-upper-text {
        font-size: 9px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 2vh;
      }

      .info-bottom-text {
        font-size: 12px !important;
        font-weight: 600;
      }

      .info-bottom-dropdown {
        display: flex;

        .dropdown-outter-text {
          font-size: 9px !important;
          font-weight: 500;
          margin: 0 5px;
        }

        .ant-btn {
          height: 2.5vh !important;
          padding: 0 5px !important;
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover,
          &:active,
          &:focus {
            color: #ff6b00 !important;
            border-color: #ff6b00 !important;
          }

          span {
            font-size: 12px !important;
            font-weight: 600;
          }

          i {
            margin: 3px 0 0 8px !important;
          }
        }
      }
    }
  }
}

.medical-card {
  margin: 2px 0 !important;

  .ant-card-head {
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 3px 24px 10px 24px !important;

    .info-card-container {
      .info-bottom-text {
        text-transform: capitalize;
      }
    }
  }

  .badge-card {
    border-bottom: 1px solid #ececec;
    padding: 0 0 4px 0 !important;

    .badge-card-title {
      font-size: 9px !important;
    }

    .badges-container {
      margin: 5px 0 !important;
      display: flex;
    }

    .badge {
      padding: 0 1vh;
      margin-right: 5px;
      display: flex;
      border-radius: 5px;
      height: 17px;
      font-size: 9px !important;
    }

    .danger-badge {
      background-color: #e53d2e;
      color: #fff;
      border: #e53d2e solid 1px;
      font-weight: 600;
    }

    .caution-badge {
      background-color: #fff;
      color: #ff4613;
      border: #ff4613 solid 1px;
    }

    .okay-badge {
      background-color: #fff;
      color: #26ac00;
      border: #26ac00 solid 1px;
    }
  }

  .info-upper-text {
    font-size: 9px !important;
    margin: 4px 0 !important;
  }

  .info-bottom-text {
    font-size: 9px !important;
    font-weight: 600;
  }

  .medical-prescriptions {
    border-bottom: 1px solid #ececec;
    padding: 2px 0;
  }

  .sup-diet-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;

    .medical-supplements {
      border-right: 1px solid #ececec;
      width: 50%;
      padding-right: 5px;
    }

    .medical-diet {
      padding-left: 5px;
    }
  }
}

.notes-card {
  overflow: auto;
  margin: 2px 0 !important;
  height: auto;

  .ant-card-head {
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    padding: 0 10px 0 24px !important;

    .ant-card-head-wrapper {
      display: flex;
      width: 100%;
      height: 100%;

      .notes-card-button {
        height: 3vh !important;
        display: flex;
        align-items: center;
        padding: 0 10px !important;
        text-transform: none;
      }
    }
  }

  .ant-card-body {
    padding: 2px 5px 2px 24px !important;

    .macros-note-card {
      border-bottom: 1px solid #ececec;
      padding: 5px 0 !important;

      .ant-card-body {
        padding: 0 !important;
        display: flex;

        .note-card-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            cursor: pointer;
          }
        }

        .notes-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .notes-user-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ant-card-meta-avatar {
              padding-right: 10px !important;

              .ant-avatar {
                border-radius: 50% !important;
                width: 20px !important;
                height: 20px !important;
              }
            }

            .notes-user-container-name {
              font-size: 11px;
              font-weight: 600;
            }
          }

          .notes-card-header-date {
            font-size: 9px !important;
          }
        }

        .note-card-changes-message {
          font-size: 9px !important;
          font-weight: 600;
          margin: 5px 0;
        }

        .notes-card-body {
          font-size: 9px !important;
          font-weight: 600;
          margin: 10px 0;
        }
      }
    }
  }
}

.macroTableMessage {
  h5 {
    color: #2c4eb9;
    text-transform: capitalize;
    font-weight: 600;
  }

  p {
    font-size: 10px;
    color: #383838;
  }
}

.rest-day-macros {
  .ant-card-head {
    color: #5c7ce2 !important;
  }

  .macros-card-label {
    width: calc(100% / 6) !important;
    font-size: 9px;
    color: #959595;
    text-align: center;
  }

  .macros-card-data {
    width: calc(100% / 6) !important;
    text-align: center;
    padding: 0 10px !important;
  }

  .macros-card-totals {
    border-top: 1px solid #5c7ce2 !important;

    .macros-card-total {
      width: calc(100% / 6) !important;
    }

    .total-label {
      width: calc((100% / 6) * 4) !important;
    }
  }
}

.training-day-macros {
  .ant-card-head {
    color: #ff6b00 !important;
  }

  .macros-card-totals {
    border-top: 1px solid #ff6b00 !important;
  }
}

.alternate-macros {
  box-shadow: none !important;

  .ant-card-head {
    color: #828282 !important;
    font-weight: 500 !important;

    .ant-card-head-wrapper {
      display: flex;
      width: 100%;
      height: 100%;

      .alternate-macros-button {
        height: 3vh !important;
        display: flex;
        align-items: center;
        padding: 0 10px !important;
        text-transform: none;
      }
    }
  }

  .ant-card-body {
    color: #828282 !important;

    .macros-card-label,
    .macros-card-data {
      font-weight: 500 !important;
    }
  }

  .macros-card-totals {
    color: #828282 !important;
    border-top: 1px solid #828282 !important;

    .macros-card-total {
      font-weight: 500 !important;
    }
  }
}

.alternate-macros-active {
  .ant-card-head {
    color: #ff6b00 !important;
  }

  .macros-card-totals {
    border-top: 1px solid #ff6b00 !important;
  }
}

.macros-card {
  margin: 2px 0;

  .ant-card-head {
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 0 10px !important;

    .macros-card-labels {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      margin-top: 10px;

      .macros-card-label {
        width: calc(100% / 7);
        font-size: 9px;
        color: #959595;
        text-align: center;
      }
    }

    .macros-card-meassurements {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      margin: 15px 0;

      .macros-card-data {
        width: calc(100% / 7);
        text-align: center;
      }
    }

    .label-left {
      text-align: right !important;
      padding: 0 10px 0 0 !important;
    }

    .macros-card-totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #000;
      height: 6vh;

      .macros-card-total {
        font-size: 11px;
        font-weight: bold;
        width: calc(100% / 7);
        text-align: center;
      }

      .total-label {
        width: calc((100% / 7) * 5) !important;
        text-align: right;
      }
    }
  }
}

.footer-container {
  display: flex;
  margin: 10px 0 5px 0;
  .ant-btn {
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    height: 25px;
    color: #fff;
    margin: 0 5px;
  }

  .footer-save-button {
    background-color: #ff6b00;
    border: solid 1px #ff6b00;

    &:hover {
      border: solid 1px #ff6b00;
      color: #ff6b00;
      background-color: #fff;
    }
  }

  .footer-send-button {
    background-color: #ff4613;
    border: solid 1px #ff4613;

    &:hover {
      border: solid 1px #ff4613;
      color: #ff4613;
      background-color: #fff;
    }
  }
  .ant-btn-disabled {
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
  }
}

.macros-card-rest-day {
  margin: 2px 0;

  .ant-card-head {
    color: #5c7ce2 !important;
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 0 10px !important;

    .macros-card-labels {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      margin-top: 10px;

      .macros-card-label {
        width: calc(100% / 7);
        font-size: 9px;
        color: #959595;
        text-align: center;
      }
    }

    .macros-card-meassurements {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      margin: 15px 0;

      .macros-card-data {
        width: calc(100% / 7);
        text-align: center;
      }
    }

    .label-left {
      text-align: right !important;
      padding: 0 10px 0 0 !important;
    }

    .macros-card-totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #000;
      height: 6vh;

      .macros-card-total {
        font-size: 11px;
        font-weight: bold;
        width: calc(100% / 7);
        text-align: center;
      }

      .total-label {
        width: calc((100% / 7) * 5) !important;
        text-align: right;
      }
    }
  }
}

.macros-card-alter-macro > s {
  margin: 2px 0;

  .ant-card-head {
    color: #828282 !important;
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 0 10px !important;

    .macros-card-labels {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      margin-top: 10px;

      .macros-card-label {
        width: calc(100% / 7);
        font-size: 9px;
        color: #959595;
        text-align: center;
      }
    }

    .macros-card-meassurements {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      margin: 15px 0;

      .macros-card-data {
        width: calc(100% / 7);
        text-align: center;
      }
    }

    .label-left {
      text-align: right !important;
      padding: 0 10px 0 0 !important;
    }

    .macros-card-totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #000;
      height: 6vh;

      .macros-card-total {
        font-size: 11px;
        font-weight: bold;
        width: calc(100% / 7);
        text-align: center;
      }

      .total-label {
        width: calc((100% / 7) * 5) !important;
        text-align: right;
      }
    }
  }
}

.macros-card-training-day {
  margin: 2px 0;

  .ant-card-head {
    color: #ff6b00 !important;
    min-height: 35px !important;
    height: 2vh !important;
    font-size: 11px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    padding: 0 10px !important;

    .macros-card-labels {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      margin-top: 10px;

      .macros-card-label {
        width: calc(100% / 7);
        font-size: 9px;
        color: #959595;
        text-align: center;
      }
    }

    .macros-card-meassurements {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      margin: 15px 0;

      .macros-card-data {
        width: calc(100% / 7);
        text-align: center;
      }
    }

    .label-left {
      text-align: right !important;
      padding: 0 10px 0 0 !important;
    }

    .macros-card-totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #000;
      height: 6vh;

      .macros-card-total {
        font-size: 11px;
        font-weight: bold;
        width: calc(100% / 7);
        text-align: center;
      }

      .total-label {
        width: calc((100% / 7) * 5) !important;
        text-align: right;
      }
    }
  }
}

.modal-macros-card-data {
  width: calc(100% / 5) !important;
  text-align: center;
  padding: 0 10px !important;
}
.modal-card-data-change {
  color: #000;
  margin-left: 36px;
  padding: 3px 6px 3px 6px;
  background-color: #ffde00;
}
.modal-card-data-nochange {
  color: #000;
  margin-left: 36px;
}

.modal-macros-card-labels {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  margin-top: 10px;

  .modal-macros-card-label {
    width: calc(100% / 5);
    font-size: 9px;
    color: #959595;
    text-align: center;
  }
}
