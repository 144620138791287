.day-note-popover {
  .ant-popover-content {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0 0 5px 5px;

    .ant-popover-title {
      border-radius: 5px 5px 0 0;
      background-color: #5c7ce2;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

    .ant-popover-inner-content {
      padding: 12px 16px !important;
      color: #5d5d5d;
      font-size: 9px;
      font-weight: 500;
    }
  }
}

.user-progress-tab {
  .ant-tabs-tab {
    font-size: 11px;
  }
}
